ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    margin-right: 20px;
  }

  nav {
    position: fixed;
    top: 5;
    left: 5;
    z-index: 999;
  }
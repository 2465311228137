.location-container {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: stretch; */
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #f0f2f5;
}

.location-card-cover {
    width: 100%;
    height: 200px;
    background-size: 100% auto;
    background-position: center center;
}

/* .location-footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
} */
@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink-high {
  to {
    background-color: red;
    color: white;
  }
}

@keyframes blink-low {
  to {
    background-color: blue;
    color: white;
  }
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.spin {
  animation: spin .5s linear infinite;
}

.container-ground {
  width: 1920px;
  height: 1080px;
  position: relative;
  background-image: url('/sodo.jpeg');
  background-size: cover;
}

.container-floor1 {
  width: 1920px;
  height: 1080px;
  position: relative;
  background-image: url('assets/f1.jpg');
  background-size: cover;
}

.container-header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 15px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.container-footer .btn-group .btn a {
  color: white;
}

.alert-high {
  animation: blink-high .5s linear 1s infinite alternate-reverse;
}

.alert-low {
  animation: blink-low .5s linear 1s infinite alternate-reverse;
}

.disconnected {
  background-color: rgb(114, 114, 114);
}

.value {
  width: 30px;
  height: 20px;
  /* background-color: red; */
  text-align: center;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  border-radius: 35px;
  line-height: 20px;
}

.alert-container {
  position: absolute;
  top: 60px;
  right: 10px;
  /* background-color: aqua; */
}